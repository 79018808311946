import { createApp } from 'vue'
import App from './App.vue'
import router from './route'
import store from './store'
// import { createPinia } from 'pinia'

import pear from "./components";

import antd from 'ant-design-vue/es'
import './assets/css/index.less'
import 'ant-design-vue/dist/antd.less'
import './global.less' // global style

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'

function useVxeTable (app: any) {


  VXETable.setup({
    size: 'mini',
    table: {
      border: true,
      stripe: true,
      height: 300,
      align: 'center',
      emptyText: '空',
      // showOverflow:true,
    }
  })

  VXETable.use(VXETablePluginExportXLSX)

  // 给 vue 实例挂载内部对象，例如：
  // app.config.globalProperties.$XModal = VXETable.modal
  // app.config.globalProperties.$XPrint = VXETable.print
  // app.config.globalProperties.$XSaveFile = VXETable.saveFile
  // app.config.globalProperties.$XReadFile = VXETable.readFile

  app.use(VXETable)

}


const app = createApp(App)

app.use(router)
app.use(store)
app.use(antd)
app.use(pear)
  .use(useVxeTable)
// app.use(createPinia())
app.mount('#app')
