import { RouteRecordRaw } from 'vue-router'

/// 基础路由
export default  <Array<RouteRecordRaw>> [
  {
    path: '/',
    redirect: "/dashboard/console",

  },
  {
    path: '/login',
    component: () => import('@/views/account/login.vue'),
    meta: {
      title: '登录'
    },
   
  }
]
