/// 动态路由
import Layout from "@/layout/index.vue";

export default {
  Layout,

  'account-center': () => import('@/views/account/center.vue'),

  'dashboard-console': () => import('@/views/dashboard/console.vue'),
  'dashboard-workspace': () => import('@/views/dashboard/workspace.vue'),

  'result-success': () => import('@/views/result/success.vue'),
  'result-failure': () => import('@/views/result/failure.vue'),
  'result-warning': () => import('@/views/result/warning.vue'),
  'result-info': () => import('@/views/result/info.vue'),

  'error-403': () => import('@/views/error/403.vue'),
  'error-404': () => import('@/views/error/404.vue'),
  'error-500': () => import('@/views/error/500.vue'),

  'table-list': () => import('@/views/list/tableList.vue'),


  // 基础
  'base-time-slot': () => import('@/pages/base/time-slot/index.vue'),
  'base-ground': () => import('@/pages/base/ground/index.vue'),

  // 预订
  'order-configure': () => import('@/pages/order/configure/index.vue'),
  'order-book': () => import('@/pages/order/book/index.vue'),
  'order-book-list': () => import('@/pages/order/book_list/index.vue'),

  'configure-system': () => import('@/pages/configure/system/index.vue'),
  'configure-wecom': () => import('@/pages/configure/wecom/index.vue'),


}
