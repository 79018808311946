import axios from 'axios'
import { AxiosRequestConfig, Method } from 'axios'
import { notification, message } from "ant-design-vue";

import store from '@/store'

// axios.defaults.headers = { "Content-Type": "application/json;charset=utf8" };

const prefix = ''

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:7888' + '/' + prefix : 'https://sjorderd.meatou.cn/api'

axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem("USER_TOKEN");
    if (accessToken) { config.headers.authorization = accessToken }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 请求拦截器
// axios.interceptors.request.use(
//   config => {
//     const loginState = cloud.auth.hasLoginState()
//     if (loginState) {
//       const authHeader = cloud.auth.getAuthHeader();
//       config.headers = { ...config.headers, ...authHeader }
//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error);
//   }
// );

// 响应拦截器

axios.interceptors.response.use(
  response => {

    console.group('响应拦截')
    console.log(response)
    console.groupEnd()

    // Some example codes here:
    // code == 20000: success
    // code == 50001: invalid access token
    // code == 50002: already login in other place
    // code == 50003: access token expired
    // code == 50004: invalid user (user not exist)
    // code == 50005: username or password is incorrect
    // You can change this part for your own usage.

    if (response.data.code !== 20000) {

      console.log(response.data.code)

      switch (response.data.code) {
        case 10042:
          store.dispatch("user/logout");
          break;
        case 50003:
          // ElMessageBox({
          //   title: '您的令牌已经过期',
          //   message: '点击重新登录重新获取令牌',
          //   showCancelButton: false,
          //   confirmButtonText: '重新登录',
          // }).then(() => {
          //   UserModule.LogOut()
          //   location.reload()
          // })

          break;

        default:
          console.log('default')
          message.error(response.data.message || '发生错误');

          return Promise.reject(response.data.message);
          break;
      }

    } else {

      return response.data
    }

  },
  error => {
    if (process.env.NODE_ENV === 'development') {
      console.log('反馈错误')
      console.log(error.message)
    }
    message.error(error.message || '发生错误');
    return Promise.reject(error);
  }
);

interface requestOptions {
  method?: Method
  loading?: boolean
  message?: boolean
  module?: string
  upload?: boolean
}

export async function request (url: string, data?: any, options?: requestOptions) {

  // Loading 事件
  if (options && options.loading) {
    //  APPModule.SET_LOADING(true)
  }

  const axiosOptions: AxiosRequestConfig = {
    method: 'get'
  }

  // 访问不同模块
  if (options && options.module) {
    axiosOptions.baseURL = options.module
  } else {
    axiosOptions.baseURL = process.env.VUE_APP_BASE_API
  }

  // 拼接 Url
  axiosOptions.url = url

  // 方法处理
  if (options && options.method) {
    axiosOptions.method = options.method
  }

  // 参数处理
  if (data) {
    if (axiosOptions.method === 'get') {
      axiosOptions.params = data
    } else {
      axiosOptions.data = data
    }
  }

  // 上传逻辑
  if (options && options.upload) {
    // axios.defaults.headers = { 'Content-Type': 'multipart/form-data' };
  }

  try {

    const response: any = await axios(axiosOptions)

    if (options?.message) {
      message.success(response.message || '操作成功')
    }

    return response

  } catch (error: any) {

    if (options?.message) {
      message.error(error.message || '操作失败')
    }
    return Promise.reject(error);

  } finally {

    if (options && options.loading) {
      // APPModule.SET_LOADING(false)
    }

  }

}


export default request
