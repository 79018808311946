import { App } from 'vue'

import Authority from './authority/index';
import Count from './count/index';
import Demo from './demo/index';
import Footer from './footer/index';
import Header from './header/index';
import Icon from './icon/index';
import IconPicker from './iconPicker/index';
import Layout from './layout/index';
import Quick from './quick/index';

import Form from './form/index'

export default function (app: App) {
  app.component('p-authority', Authority)
  app.component('p-count', Count)
  app.component('p-demo', Demo)
  app.component('page-footer', Footer)
  app.component('page-header', Header)
  app.component('p-icon', Icon)
  app.component('p-icon-picker', IconPicker)
  app.component('page-layout', Layout)
  app.component('quick', Quick)
  app.component('m-form', Form)

}
