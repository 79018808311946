
import { onUnmounted } from "vue";

/**
 * 开发环境
 */
export const isNotProduction = () => {
  return process.env.NODE_ENV !== 'production'
}

/**
 * 定时时间
 * 
 * @param timeout   超时事件
 * @param callback  回调事件
 */
export const isTimeout = (timeout:any, callback:any) => {
  setTimeout(() => {
    callback();
  }, timeout.value * 1000);
  const Interval = setInterval(() => {
    timeout.value--;
  }, 1000);
  onUnmounted(() => {
    clearInterval(Interval);
  });
}

/**
 * 根据 当前路径 查询 所有父级 (包括当前)
 * 
 * @param arr 菜单列表
 * @param id 指定路由
 */
 export const findParentAll = (arr:any, id:any) => {
  let temp:any[] = []
  var forFn = function (list:any, path:any) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i]
      if (item.path === path) {
        temp.push(findPathById(arr, item.id))
        forFn(list, item.parent);
        break
      } else {
        if (item.children) {
          forFn(item.children, path)
        }
      }
    }
  }
  forFn(arr, id)
  return temp
}

/**
 * 根据 当前路径 查询 所有父级 
 * 
 * @param arr 菜单列表
 * @param id  当前路由
 */
export const findParent = (arr:any, id:any) => {
  let temp:any[] = []
  var forFn = function (list:any, path:any) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i]
      if (item.path === path) {
        temp.push(findPathById(arr, item.parent))
        forFn(list, item.parent);
        break
      } else {
        if (item.children) {
          forFn(item.children, path)
        }
      }
    }
  }
  forFn(arr, id)
  return temp
}

/**
 * 根据 当前路径 查询 菜单编号
 * 
 * @param arr 菜单列表
 * @param id  当前路由
 */
export const findPathById = (arr:any, key:any) => {
  var forFn = function (list:any, id:any) {
    for (var i = 0; i < list.length; i++) {
      var item = list[i]
      if (item.id === id) {
        return item.path;
      } else {
        if (item.children) {
          forFn(item.children, name)
        }
      }
    }
  }
  return forFn(arr, key);
}


export function deepClone (source: any): any {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments')
  }
  const targetObj: any = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}